* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "poppins-bold";
  src: url(../fonts/poppins-bold.ttf);
}

@font-face {
  font-family: "Poppins-light";
  src: url(../fonts/poppins-light.ttf);
}

@font-face {
  font-family: "poppins-regular";
  src: url(../fonts/poppins-regular.ttf);
}

@font-face {
  font-family: "poppins-semibold";
  src: url(../fonts/poppins-semibold.ttf);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}


.title-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
  margin-bottom: 10px;
}

.search {
  border: 1px solid #d4d9e1;
  width: 200px !important;
  height: 28px;
  margin-right: 10px !important;
}

.search input::placeholder {
  color: #919cac;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px !important;
}

.dashboard-text p,
.title-main .page-title {
  color: #122b45;
  font-size: 16px;
  line-height: 20px;
}

.common-button button {
  font-size: 12px;

  padding: 7px 13px 6px 13px;
  line-height: 15px;
  box-shadow: none !important;
  letter-spacing: 0px;
  border-radius: 10px;
  text-transform: capitalize;
}

.grey-button button,
.grey-button button:hover {
  background: #edeff1;
  border: 1px solid #d4d9e1;
  color: #122b45;
}

.blue-button button,
.blue-button button:hover {
  background: #0d70d9;
  color: #ffffff;
  border: 1px solid #0d70d9;
  margin-left: 10px;
}

/* Page-not-found */
.pagenotfound-text-heading {
  margin-top: 10px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center;
}
.pagenotfound-text {
  text-align: center;
}
.pagenotfound-img {
  margin-top: 10px !important;
  margin-left: 10% !important;
  width: 80% !important;
  height: 70% !important;
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 12px;
  color: #122b45;
  line-height: 15px;
}

.table-design-main tr th {
  padding: 4px 9px !important;
}

.table-design-main tr td {
  padding: 5px 9px !important;
  border-bottom: 0 !important;
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.admin-input-design {
  width: 100%;
}

.input-design-div.with-border input {
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 15px;
  height: 40px;

  line-height: 28px;
}

.with-border .MuiFilledInput-underline {
  height: 40px !important;
  font-size: 14px !important;
  letter-spacing: 0;
}

.error-msg{
  text-align: left;
  color: red;
  margin-left: 10px !important;
  font-size: 12px !important;
}

.input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;

  padding-bottom: 8px !important;
  display: flex;
}

.line {
  height: 1px;
  background: #ebebeb;
}

.save-btn button {
  min-width: 130px;
}

.table-container {
  box-shadow: none !important;
}
.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}
.set-date-picker .MuiInputBase-colorPrimary {
  background: transparent;
  border: 1px solid #00000033 !important;
  border-radius: 10px;
  width: 100%;
  height: 28px;
}
.set-date-picker fieldset {
  border-color: transparent !important;
}
.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;

  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #0d70d9;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;

  color: #99a4b5;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #122b45;
}

.pagination-design {
  margin-top: 4px;
}

.filter-btn button {
  max-width: 28px;
  height: 28px;
  min-width: 28px;
  background: #122b45 !important;
  border: 1px solid #122b45;
}

/* 10-5 css */

.forgot p {
  margin: 0px !important;
  text-align: center !important;
}

.input-design-div .MuiInputBase-input {
  /* padding: 10px 16px !important; */
  padding: 4px 10px !important;
  color: #122b45;
}

.dashboard-text p {
  color: #122b45;
  font-size: 16px;
  line-height: 20px;

  display: flex;
}

.main-box-dash {
  background-color: white;
  border-radius: 8px;
  max-width: 18.87%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  border: 1px solid #dfe0e1;
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;

  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.barge-list-main-box {
  border-radius: 8px;
  /* / max-width: 323px; / */
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  border: 1px solid #dfe0e1;
  /* / margin: 15px 6.5px 0px; / */
  background: #fff;
}

.barge-set-dash-main {
  display: flex;

  /* / width: calc(50% + 15px); / */
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;

  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;

  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;

  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;

  color: #122b45;
}

.pt-5 {
  padding-top: 5px !important;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background-color: white;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  border: 1px solid #dfe0e1;
  /* / margin: 15px 6.5px 0px; / */
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #edeff1;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.profile-set {
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #122b45;
  font-size: 14px;
  line-height: 17px;
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

.logout-profile {
  color: #f5125f !important;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

/* / start admin header css / */
.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* / end admin header css / */

/* 10-5 css end */
.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-title {
  color: #122b45;

  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: #fff;
  border: 1px solid #d4d9e1;
  color: #122b45;
}

.card-main {
  background: #fff;
  padding: 30px;
  /* box-shadow: 0px 3px 30px #0000000D; */
  border-radius: 10px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-card-main {
  width: 710px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 15px;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 1px;
  background: red;
  height: 28px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  min-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

/* start ugoout */
.set-login {
  display: flex;
}

.leftbox {
  background: linear-gradient(180deg, #233862 0%, #20222c 100%);
  max-width: 50%;
  width: 50%;
}

.rightbox {
  height: 100vh;
  max-width: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-text p {
  font-size: 18px;
  line-height: 23px;
  color: #2b478b;
  font-family: "poppins-bold";
  text-align: center;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: white;
  border: 1px solid white;
  border-radius: 10px;
  height: 40px;
  border: 1px solid #20222c1f;
}

.main-box {
  max-width: 391px !important;
  width: 391px;
  flex: 0 0 391px;
  padding: 30px;
  border: 1px solid #2b478b;
  border-radius: 10px;
}

.login-btn button {
  /* width: 41.784%; */
  width: 100%;
}

.box-text {
  margin-bottom: 36px;
}

.input-design-div .password {
  margin-top: 20px !important;
}

.login-btn-main {
  margin: 36px 0px 20px;
}

.ugo-login-text p {
  margin-bottom: 20px !important;
  font-size: 12px;
  color: #2b478b;
  line-height: 18px;
  margin: 15px 0px 40px 0px;
  font-family: "poppins-regular";
}

.box-login-text p {
  font-size: 12px;
  color: #2b478b;
  line-height: 18px;
  margin: 15px 0px 40px 0px;
  font-family: "poppins-bold";
}

.common-btn button,
.common-btn button:hover,
.common-btn .buttons,
.common-btn .buttons:hover {
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: none;
  min-height: 40px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  /* min-width: 300px; */
  background: linear-gradient(180deg, #233862 0%, #20222c 100%);
  font-family: "poppins-bold";
}

.forgot a {
  color: #2b478b;
  font-family: "poppins-bold";
}

.input-design-div .input-placeholder input::placeholder {
  color: #02091c !important;
  line-height: 18px !important;
  /* font-family: 'poppines-bold' !important; */
  font-weight: 600 !important;
  font-size: 12px !important;
}

.logo-set img {
  height: 130px;
  width: 130px;
  object-fit: contain;
}

.ugo-logo-set {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: column;
}

.ugo-logo-text p {
  color: white;
  font-family: "poppins-bold";
  font-size: 35px;
  line-height: 35px;
  margin: 20px 0px;
  letter-spacing: 2px;
}

.ugo-logo-inner p {
  color: white;
  font-size: 14px;
  line-height: 23px;
  font-family: "poppins-regular";
  text-align: center;
  letter-spacing: 1px;
}

.email-set {
  position: absolute;
  top: 12px;
  left: 12px;
}

.main-email-set {
  position: relative;
}

.email-set img {
  width: 15px;
  height: 17px;
}

.set-password-box {
  width: 100%;
}

.main-email-set svg {
  fill: #aba8c0;
}

.back-header {
  background: linear-gradient(180deg, #11074c 0%, #20222c 100%);
  padding: 31.5px 30px;
  position: relative;
}

.backheader-inner .back {
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  text-decoration: none;
  font-family: "poppins-semibold";
}

.back img {
  margin-right: 11px;
}

.dark-logo-set img {
  height: 77px;
  width: 100px;
  object-fit: contain;
}

.dark-ugo-logo-text p {
  color: black;
  font-family: "poppins-bold";
  font-size: 25px;
  line-height: 28px;
  margin: 10px 0px;
  letter-spacing: 2px;
}

.dark-ugo-logo-inner p {
  color: black;
  font-size: 13px;
  line-height: 23px;
  font-family: "poppins-regular";
  text-align: center;
  letter-spacing: 1px;
}

.bottom-box {
  display: flex;
  justify-content: center;
}

.bottom-login-btn-main {
  margin: 36px 0px 0px;
}

.auto-innercontent {
  height: calc(100vh - 84px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  flex-direction: column;
  margin-top: 84px;
}

.reset-text p {
  color: #233862 !important;
}

.reset-text {
  margin-bottom: 17px !important;
}

.reset-box-text p {
  font-size: 12px;
  /* color: #233862; */
  color: #2b478b;
  line-height: 18px;
  font-family: "poppins-regular";
  text-align: center;
  margin-bottom: 22px;
}

.bottom-reset-btn-main {
  margin: 30px 0px 0px;
}

.verify-design-div {
  max-width: 92%;
  margin: auto;
}

.verify-design-div form {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 15px;
  text-align: center;
  justify-content: center;
}

.verify-design-div .verify-design {
  margin-top: 0 !important;
  margin: 12px;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: transparent;
  border: 1px solid #00000033;
  border-radius: 10px;
  height: 40px;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div input {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  font-family: "inter-regular";
}

.input-design-div-ugo .MuiFilledInput-underline,
.input-design-div-ugo .MuiFilledInput-underline:hover,
.input-design-div-ugo .MuiFilledInput-underline.Mui-focused {
  background: transparent;
  border: 1px solid #00000033;
  border-radius: 10px;
  height: 50px !important;
  width: 50px !important;
}

.verify-box {
  margin-top: 20px;
}

.verify-btn-main {
  margin: 20px 0px 0px;
}

/* .ugo-modal-box {
  border: none !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  width: 100% !important;
  max-width: 550px !important;
} */

/* .ugo-thank-modal-box {

  width: 100% !important;
  max-width: 350px !important;
} */

.ugo-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 30px; */
  flex-direction: column;
}

.dark-ugo-modal p {
  color: #233862;
  font-size: 16px;
  line-height: 24px;
  font-family: "poppins-bold";
  text-align: center;
}

.common-btn-modal button,
.common-btn-modal button:hover,
.common-btn-modal .buttons,
.common-btn-modal .buttons:hover {
  min-width: 300px;
  /* min-width: 170px; */
}

.ugo-modal-btn {
  margin: 30px 0px 0px;
}

.dark-logo-set-modal img {
  height: 66px;
  width: 93px;
  object-fit: contain;
}

.modal-box-text p {
  font-size: 16px;
  /* color: #233862; */
  color: #2b478b;
  line-height: 21px;
  font-family: "poppins-regular";
  text-align: center;
  margin: 5px 0px;
}

.modal-input {
  margin-top: 20px;
}

.modal-input .MuiGrid-item {
  padding-top: 12px !important;
}

.ugo-login-set {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 30px; */
  flex-direction: column;
  height: 100vh;
}

/* Sidebar  */
.header-flex-main {
  position: fixed;
  top: 0;
  left: 290px;
  right: 0;
  z-index: 999;
}

.sidebar-inner-main.MuiBox-root {
  width: 290px;
  background: #fefeff;
  /* box-shadow: 0px 3px 6px hsl(0deg 0% 0% / 10%); */
  box-shadow: 0px 3px 6px hsl(0deg 0% 0% / 40%);
  height: 100%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s all;
  z-index: 999;
}

.sidebar-top {
  padding: 15px 24px 7px 24px;
}
.profile-image-main {
  display: flex;
}

.profile {
  margin-right: 25.46px;
  width: 72px;
  height: 65px;
  border-radius: 7px;
  background-color: #233862;
}

.welcome-text {
  font-family: "poppins-regular";
  font-size: 16px;
  color: hsla(249, 83%, 16%, 1);
  line-height: 21px;
}

.bussiness-name-text {
  font-size: 16px;
  font-family: "poppins-bold";
  color: hsla(249, 83%, 16%, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 160px;
}

.sidebar-ul {
  padding: 0;
}

.sidebar-ul li {
  padding: 8px 24px;
  margin-bottom: 2px;
}

.sidebar-ul li .sidebar-li-inner {
  display: flex;
  align-items: center;
}

.sidebarul-image {
  width: 28px;
  height: 22px;
  object-fit: contain;
  margin-right: 20px;
}

.sidebar-ul li.active {
  background: hsla(216, 78%, 74%, 0.33);
}

.sidebarul-image img {
  width: 22px;
}

.save-user-btn {
  min-width: 87px !important;
  background-color: #233862 !important;
  color: #ffffff !important;
  height: 36px !important;
}
.save-btn-main {
  text-align: left !important;
  padding: 10px !important;
  padding-top: 30px !important;
}
/* Sidebar end */

.dashboard-containt-main {
  width: calc(100% - 290px);
  margin-left: auto;
}

.header-main {
  background: linear-gradient(180deg, #233862 0%, #20222c 100%);
  padding: 25px 20px;
}

.ugoout-text p {
  font-size: 16px;
  line-height: 22px;
  color: white;
  font-family: "poppins-bold";
}

.logo-img {
  width: 24px;
  object-fit: contain;
  height: 24px;
}

.logo-img {
  display: flex;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 74px);
  margin-top: 74px;
}

.title-main .page-title {
  font-size: 18px;
  line-height: 20px;
  color: #11074c;
  font-family: "poppins-bold";
  text-transform: capitalize;
}

.change-input-label {
  color: #2b478b;
  font-size: 13px;
  line-height: 15px;
  /* padding-bottom: 12px !important; */
  display: flex;
  font-family: "poppins-semibold";
}

.small-btn-modal button,
.small-btn-modal button:hover,
.small-btn-modal .buttons,
.small-btn-modal .buttons:hover {
  min-width: 170px;
  margin-top: 10px;
}
.small-btn button,
.small-btn button:hover,
.small-btn .buttons,
.small-btn .buttons:hover {
  min-width: 170px !important;
}
.main-box-signin {
  max-width: 750px !important;
  width: 750px;
  flex: 0 0 750px;
  padding: 30px;
  border: 1px solid #2b478b;
  border-radius: 6px;
}

.signin-box {
  margin-bottom: 0px !important;
}

.set-getting-box {
  width: 100%;
}

.select-design em {
  color: #233862;
  font-size: 12px;
  font-family: "poppins-regular";
  line-height: 20px;
}

.set-select {
  width: 100%;
  margin: 0px !important;
}

.item-top .MuiSelect-outlined {
  background: transparent !important;
  border: 1px solid #00000033 !important;
  border-radius: 7px !important;
  padding: 9px 14px !important;
  text-align: left !important;
  color: #233862;
  font-size: 12px;
  font-family: "poppins-regular";
  line-height: 20px;
}

.set-select .MuiInputBase-colorPrimary fieldset {
  border-color: transparent !important;
}

.card-set-detail {
  border-bottom: 1px solid #cccccc;
  padding: 30px;
}

.detail-text-main .page-main-title {
  color: #2b478b;
  font-size: 16px;
  font-family: "poppins-bold";
  line-height: 20px;
}

.space-set {
  margin-bottom: 5px;
}

.set-detail-box-main {
  padding: 30px;
}
.page-title {
  text-align: left;
}
.position {
  text-align: left;
}
.detail-text-main .page-title {
  font-size: 14px;
  color: #2b478b;
  font-family: "poppins-regular";
  line-height: 22px;
}

.detail-text-main .set-span-text {
  font-family: "poppins-bold";
}

.input-design-ugo .MuiInputBase-input {
  padding-left: 14px !important;
  color: #233862 !important;
  font-size: 12px !important;
  font-family: "poppins-regular" !important;
  line-height: 20px;
}

.input-design-ugo .MuiFilledInput-input::placeholder {
  color: #222f4c !important;
  /* opacity: 1; */
}

.main-email-set .MuiInputBase-input {
  padding-left: 35px !important;
}

.table-main-div .MuiPaper-elevation {
  background: transparent;
  box-shadow: none;
}
.table-main {
  box-shadow: none;
  border-collapse: separate !important;
  border-spacing: 0 8px !important;
}
.table-main thead tr {
  background: linear-gradient(180deg, #11074c 0%, #233862 100%);
}
.table-main tr,
.table-main tr td {
  height: 30px;
  word-break: break-all;
}
.users-table tr td:nth-child(1),
.users-table tr th:nth-child(1) {
  max-width: 27%;
  width: 10%;
}
.table-main tr th {
  font-size: 15px;
  font-family: "poppins-regular";
  border: 0;
  color: white;
  padding: 10px;
  text-transform: capitalize;
}
.table-main tr td:last-child {
  border-radius: 0px 10px 10px 0px;
}
.users-table tr td {
  border-bottom: 0 !important;
  padding: 0px 10px;
}
.users-table tr {
  box-shadow: 0px 3px 6px #8888882b;
  margin-bottom: 10px;
  border-radius: 6px;
}
.table-main tr td {
  padding: 10px;
  border: 0;
  background: #fff;
  font-family: "poppins-regular";
  font-size: 12px;
  color: #2b478b;
}
.users-table tr td:nth-child(2),
.users-table tr th:nth-child(2) {
  max-width: 27%;
  width: 18%;
}
.users-table tr td:nth-child(3),
.users-table tr th:nth-child(3) {
  max-width: 28%;
  width: 12%;
}
.users-table tr td:nth-child(4),
.users-table tr th:nth-child(4) {
  max-width: 20%;
  width: 20%;
}
.users-table tr td:nth-child(5),
.users-table tr th:nth-child(5) {
  max-width: 20%;
  width: 17%;
}
.users-table tr td:nth-child(6),
.users-table tr th:nth-child(6) {
  max-width: 20%;
  width: 15%;
}
.main-sale-card {
  box-shadow: 0px 3px 6px #00000024;
  border-radius: 5px;
}
.main-sale-card-set {
  display: flex;
  justify-content: center;
}
.sale img {
  height: 250px !important;
  width: 250px !important;
  object-fit: cover;
}
.sale-text p {
  font-size: 16px;
  font-family: "poppins-bold";
  line-height: 17px;
  color: #11074c;
  /* margin-top: 10px; */
  margin-top: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.sale-bottom p {
  font-size: 14px;
  font-family: "poppins-regular";
  line-height: 17px;
  color: #11074c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.set-sale-box {
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.main-sale-bottom-box {
    padding: 0px 20px 20px 20px;
}
.live-btn-modal button,
.live-btn-modal button:hover,
.live-btn-modal .buttons,
.live-btn-modal .buttons:hover {
  min-width: 100px !important;
  background: #2b478b !important;
  border-radius: 50px !important;
  margin: 10px 0px;
  min-height: 30px !important;
  font-size: 13px !important;
  line-height: 18px !important;
}
.creat-btn button,
.creat-btn button:hover,
.creat-btn .buttons,
.creat-btn .buttons:hover {
  margin-bottom: 30px;
  margin-left: auto;
  display: flex !important;
}
.live-set-box {
  justify-content: space-between;
  display: flex;
  text-align: center;
  align-items: center;
}
.set-live-line {
  border-top: 1px solid #cccccc;
  margin: 50px 0px;
}
.set-live-text {
  margin: 20px 0px;
}
.transaction-main {
  border-bottom: none !important ;
}
.detail-text-main img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.detail-text-main a {
  display: flex;
  margin: 10px 0px;
  text-decoration: none;
  color: #2b479a;
}
.set-textarea-box {
  height: auto !important;
  overflow: hidden;
  width: 100% !important;
  resize: none;
  border-color: #00000033 !important ;
  border-radius: 7px !important;
  outline: none !important;
  padding: 10px 15px;
  height: 60px !important;
  color: #233862 !important;
  font-size: 12px !important;
  font-family: "poppins-regular" !important;
  line-height: 20px;
  box-shadow: none !important; 
}
.set-textarea-box::placeholder {
  color:rgb(145, 145, 145);
  font-weight: 500;

}
.set-date-picker .MuiInputBase-colorPrimary {
  background: transparent;
  border: 1px solid #00000033 !important;
  border-radius: 6px;
  height: 40px !important;
  padding: 6.5px 14px 6.5px 0px !important;
  /* max-width: 224px !important; */
  width: 100%;
}
.res-back-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.set-select em {
  font-style: normal;
  color: hsl(220deg 47% 26% / 50%);
  font-size: 12px !important;
  font-family: "poppins-regular";
}
.radio-set-box {
  padding-bottom: 5px !important;
}
.radio-set span svg {
  height: 18px !important;
  width: 18px !important;
}
.radio-set .MuiTypography-body1 {
  color: #233862 !important;
  font-size: 12px !important;
  font-family: "poppins-regular" !important;
}
.change-amount {
  color: #2b478b;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  font-family: "poppins-bold";
}
.set-img-transaction {
  width: 36px;
  height: 24px;
}
.common-btn a {
  text-decoration: none;
}
.sale-bottom-date p {
  font-size: 11px !important;
}
.set-tean {
  width: 36px;
  height: 24px;
  object-fit: contain;
}
.set-img-transaction img {
  width: 25px;
  height: 18px;
}
.sidebar-ul li a {
  text-decoration: none;
  color: #122b45;
}
.upload-btn {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.chart-card {
  height: 330px;
  width: 100%;
  max-width: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.transaction button,
.transaction button:hover,
.transactiont .buttons,
.transaction .buttons:hover {
  padding: 0px !important;
  min-height: auto !important;
  min-width: auto !important;
  background: transparent !important;
  box-shadow: none !important;
}
.small-card-box {
  height: 77px;
  /* width: 153px; */
  padding: 15px;
}
.small-chart {
  display: flex;
  justify-content: space-around;
  margin: 30px 0px 0px;
}
.chart-text p {
  color: #233862;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  font-family: "poppins-bold";
}
.chart-number p {
  margin-top: 5px;
  font-size: 20px !important;
}
.res-icon-set {
  display: none;
}
.input-line:after {
  /* top: calc(100% - 73%);
  bottom:calc(100% - 73%); */
  width: 1px;
  height: 95%;
  content: "";
  display: flex;
  background: #2b478b;
  position: absolute;
  left: 50%;
  top: 16px;
}
.input-line {
  position: relative;
}
.input-line-signln:after {
  width: 1px;
  height: 97%;
  content: "";
  display: flex;
  background: #2b478b;
  position: absolute;
  left: 50%;
  top: 8px;
}
.input-line-signln {
  position: relative;
}

.upload-btn-set button,
.upload-btn-set button:hover,
.upload-btn-set .buttons,
.upload-btn-set .buttons:hover {
  padding: 0px !important;
  min-height: auto !important;
  min-width: auto !important;
  background: #122b45;
}
.radio-set .Mui-checked {
  color: #233862 !important;
}

.set-total-amount-text {
  margin: 10px 0px;
}
.input-verification .MuiInputBase-input {
  font-size: 15px !important;
  font-family: "poppins-bold" !important;
  text-align: center;
}
.card-border {
  padding: 20px 30px;
}
.res-dark-ugo-modal {
  margin: 0px 0px 5px;
}
.sale-bottom {
  margin: 0px 0px 5px;
}
.payment-span {
  font-family: "poppins-bold";
}

.admin-submenu-listitem-main {
  display: flex !important;
}
.submenu-main {
  display: flex !important;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 5px;
}

.admin-sidebar-submenulist
  .admin-sidebar-listitem:first-child
  .admin-sidebar-link {
  margin-top: 0px;
}

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: relative;
  right: 0px;
  left: 100px;
}

.admin-sidebar-link.active {
  color: #11074b;
  background-color: #d1d4e2;
  border-radius: 4px;
}
.admin-sidebar-link {
  font-family: "poppins-regular";
  font-size: 15px;
  line-height: 19px;
  color: #171854;
  display: flex;
  padding: 10px 0px 8px 8px;
  /* margin-bottom: 10px; */
  width: 100%;
  /* text-decoration: none; */
}

.sidebar-setting-box {
  display: flex !important;
  margin-left: 5px !important;
}
.sidebar-setting-icon {
  margin-right: 25px !important;
  color: #88b2f0 !important;
}

/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}
