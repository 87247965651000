.expandless-icon {
  left: 120px !important;
}

.expandmore-icon {
  left: 120px !important;
}
.cms-text {
  margin-top: 2px !important;
  margin-left: 1px !important;
}

.sidebar-text {
  font-family: "poppins-bold";
  font-size: 16px;
  line-height: 19px;
}
a .sidebar-text .cms-dropdown {
  display: block !important;
}

.cms-dropdown {
  margin-left: 20px !important;
  padding: 10px 0px 8px 8px;
}

.cms-dropdown.active {
  color: #11074b;
  background-color: #e6e8f2;
  border-radius: 4px;
  width: 30vh;
}

.admin-sidebar-link.active {
  color: #11074b;
  background-color: #d1d4e2;
  border-radius: 4px;
}

.admin-sidebar-link {
  font-family: "poppins-regular";
  font-size: 15px;
  line-height: 19px;
  color: #171854;
  display: flex;
  padding: 10px 0px 8px 8px;
  /* margin-bottom: 10px; */
  width: 100%;
  /* text-decoration: none; */
}

.privacy-policy {
  padding-top: 0px !important;
}

.cms-container {
  margin-top: 8px !important;
}
