.ugo-modal-box {
    /* border: none !important; */
    border-radius: 6px !important;
    /* box-shadow: none !important; */
    width: 100% !important;
    max-width: 550px !important;
    border: 0.5px solid rgb(251, 245, 245) !important;
    background-color: azure;
  }

  .ugo-thank-modal-box {
    border: 0.5px solid rgb(248, 238, 238) !important;
    width: 100% !important;
    max-width: 350px !important;
  }
  
  .ugo-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 30px; */
    flex-direction: column;
  }

  .dark-logo-set-modal img {
    height: 66px;
    width: 93px;
    object-fit: contain;
  }
  
  .dark-ugo-logo-text p {
    color: black;
    font-family: 'poppins-bold';
    font-size: 25px;
    line-height: 28px;
    margin: 10px 0px;
    letter-spacing: 2px;
  }

.failed-text{
  color: red !important;
}